import React, { useEffect, useState } from 'react';

function App() {
  const [jobs, setJobs] = useState([]);
  const version = process.env.REACT_APP_VERSION;
  useEffect(() => {
    const apiUrl = process.env.REACT_APP_BACKEND_URL;
    fetch(`${apiUrl}/jobs`)
      .then((response) => response.json())
      .then((data) => setJobs(data))
      .catch((error) => console.error('Error fetching jobs:', error));
  }, []);

  return (
    <div className="App">
      <h1>Job Board v:{version}</h1>
      <ul>
        {jobs.map((job) => (
          <li key={job.id}>
            <h2>{job.title}</h2>
            <p>{job.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;
